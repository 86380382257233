<template>
    <div class="assessment">
        <lottie-player v-if="showLottie" class="lottie" src="https://assets2.lottiefiles.com/packages/lf20_xmyffole.json" background="transparent" speed="1" style="width: 100%; height: 100vh;" autoplay></lottie-player>

        <div v-else>
            <div class="header-container">
                <p class="title">{{ $t('card_assessment') }}</p>
                <p class="desc">{{ $t('pick_a_card') }}</p>
                <img @click="goBack" class="close" :src="require('@/assets/img/close.svg')" alt="close">
            </div>

            <Onebyone
                v-if="isSlider"
                :cards="cards"
                :selected="selected"
                @removeItem="addRemoveItem"
            />

            <Spreadout
                v-else
                :cards="cards"
                :selected="selected"
                @removeItem="addRemoveItem"
            />

            <div class="tab-selector" v-if="windowWidth >= 860">
                <div @click="isSlider = false" :class="{active : !isSlider}">{{ $t('one_by_one') }}</div>
                <div @click="isSlider = true" :class="{active : isSlider}">{{ $t('spread_out') }}</div>
            </div>

            <div class="footer">
                <p class="selected">{{ $t('selected_card') }}: {{ this.selected.length }}</p>

                <div class="buttons">
                    <button @click="goBack" class="cancel">{{ $t('cancel') }}</button>
                    <button @click="showModal = true" class="next" :disabled="isNextDisabled">{{ $t('next') }}</button>
                </div>
            </div>
            
            <div class="modal-container" v-if="showModal">
                <div class="modal">
                    <p class="title">{{ $t('selected_card_summary') }}</p>
                    <p class="desc">{{ $t('selected_card_summary_desc') }}</p>
                    <img @click="showModal = false" class="close" :src="require('@/assets/img/close.svg')" alt="close">

                    <div class="items-wrapper">
                        <div 
                            :class="['item', { disabled: isDisabled(item) }]"
                            v-for="(item, key) in selected"
                            :key="key"
                        >
                            <img :src="require(`@/assets/img/cards/${item.id}-${language}.png`)" alt="card">
                            <p @click="addRemoveItem(item)" class="remove">{{ $t('remove') }}</p>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button @click="next()" class="next" :disabled="isNextDisabled">{{ $t('next') }}</button>
                        <button @click="showModal = false" class="cancel">{{ $t('cancel') }}</button>
                    </div>
                </div>
            </div>

            <div class="confirm-container" v-if="showConfirm">
                <div class="confirm">
                    <p class="title">{{ $t('would_you_like_to_proceed') }}</p>
                    <p class="desc subscribed">{{ $t('if_you_close') }}</p>
                    
                    <div class="buttons"> 
                        <button @click="close()" class="close" :disabled="loading">{{ $t('close') }}</button>
                        <button @click="cancel" class="return" :disabled="loading">{{ $t('return') }}</button>
                    </div>
                </div>

                <!-- <div class="confirm" v-else>
                    <p class="title">{{ $t('wait') }}</p>
                    <p class="desc subscribed">{{ $t('if_you_proceed') }}</p>
                    
                    <div class="buttons">
                        <button @click="close(true)" class="close" :disabled="loading">{{ $t('close') }}</button>
                        <button @click="cancel" class="return" :disabled="loading">{{ $t('return') }}</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
// import { CARDS } from "@/utils/cards.js";
import { mapFields } from 'vuex-map-fields'

export default {
    data () {
        return {
            // cards: CARDS,
            // selected: [],
            minItem: 6,
            maxItem: 8,
            isSlider: false,
            showModal: false,
            showConfirm: false,
            showLottie: false,
            windowWidth: window.innerWidth
        }
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },

    beforeRouteLeave (to, from, next) {
        if (!this.showConfirm && to.name !== 'card-summary') {
            this.goBack()
        } else {
            this.showConfirm = false
            next()
        }
    },

    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize);
    },

    components: {
        Onebyone: () => import('@/components/game/Onebyone'),
        Spreadout: () => import('@/components/game/Spreadout')
    },

    watch: {
        windowWidth(newWidth) {
            // console.log(`it changed to ${newWidth} from ${oldWidth}`)

            this.isSlider = newWidth < 860
        }
    },

    computed: {
        ...mapFields({
            cards: 'cards',
            selected: 'selectedCards',
            hasTie: 'hasTie',
            completed: 'completed',
            language: 'language',
            loading: 'loading',
            user: 'user',
            ableToAddFromReason: 'ableToAddFromReason'
        }),

        isNextDisabled () {
            if (this.hasTie) {
                if (this.completed.length === this.selected.length) return true
                return !(this.selected.length >= this.minItem && this.selected.length <= 10)
            } else {
                let max = this.ableToAddFromReason ? 10 : this.maxItem
                return !(this.selected.length >= this.minItem && this.selected.length <= max)
            }
        }
    },

    async created () {
        if (!(this.user.subscription.id && this.user.subscription.takes > 0)) {
            this.$store.commit('TOGGLE_BANNER', {
                status: true,
                message: this.$t('no_takes_left')
            })

            this.$router.push('dashboard').catch(()=>{})
            return
        }

        if (this.selected.length < 1) {
            this.showLottie = true
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.showLottie = false
            }, 10000)
        }

        await this.$store.dispatch('getCards')
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },

        isSelected (val) {
            return this.selected.find(item => item.id === val.id)
        },

        addRemoveItem (val) {
            if (!this.isSelected(val)) {
                if (this.hasTie) {
                    if (this.selected.length < 10) {
                        this.selected = [...this.selected, val]
                    } else {
                        this.$store.commit('TOGGLE_BANNER', {
                            status: true,
                            message: this.$t('you_reached_the_maximum_card_selected')
                        })
                        return
                    }
                } else {
                    let max = this.ableToAddFromReason ? 10 : 8
                    if (this.selected.length < max) {
                        this.selected = [...this.selected, val]
                    } else {
                        // this.alertActive = true
                        this.$store.commit('TOGGLE_BANNER', {
                            status: true,
                            message: this.$t('you_reached_the_maximum_card_selected')
                        })
                        return
                    }
                }

            } else {
                this.selected = this.selected.filter(item => item.id !== val.id)
            }
        },

        next () {
            this.ableToAddFromReason = false
            this.$router.push('/card-summary')
        },

        isDisabled (val) {
            return this.completed.find(item => item.id === val.id)
        },

        goBack () {
            this.showConfirm = true
        },

        cancel () {
            this.showConfirm = false
        },

        async close () {
            // if (val) await this.$store.dispatch('cancelAssessment')
            this.$router.push('dashboard')
            this.$store.commit('CLEAR_GAME_VARIABLES')
        },
    }
}
</script>

<style lang="scss" scoped>
.assessment {
    color: #221E1F;
    padding-top: 20px;

    .header-container {
        position: relative;
        text-align: center;
        margin-bottom: 22px;

        .title {
            font-family: 'Crimson Pro', sans-serif;
            font-weight: 700;
            font-size: 26px;
            margin-bottom: 17px;
        }

        .desc {
            font-size: 14px;
        }

        .close {
            cursor: pointer;
            position: absolute;
            top: 0px;
            right: 20px;

            width: 15px;
            height: 15px;

            @include desktop {
                width: unset;
                height: unset;
            }
        }
    }

    .tab-selector {
        width: 215px;
        height: 40px;
        position: fixed;
        left: -70px;
        top: 270px;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-90deg);
        // background-color: #ffffff;
        z-index: 999;

        @include desktop {
            top: 0px;
        }
        
        div {
            font-weight: 600;
            cursor: pointer;
            padding: 10px 15px;
            background-color: #ffffff;
            box-shadow: 0 3px 6px #00000047;
            // color: #ffffff;
            // border-radius: 4px;

            &.active {
                background-color: #00AEEF;
                color: #ffffff;
                border-radius: 4px;
                box-shadow: unset;
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        position: fixed;
        padding: 10px;
        width: 100%;
        box-shadow: 0px -6px 6px #00000047;
        background-color: #ffffff;

        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        @include desktop {
            padding: 20px 50px;
        }

        .selected {
            flex: 1;
            font-weight: 600;
            font-size: 18px;
        }

        button {
            width: 80px;
            font-weight: 600;

            @include desktop {
                width: 125px;
            }

            &.cancel {
                background-color: #ffffff;
                border: 1px solid #FF6868;
                color: #FF6868;
                margin-right: 8px;

                @include desktop {
                    margin-right: 16px;
                }
            }

            &.next {
                background-color: #00AEEF;
                border: 1px solid #00AEEF;
                color: #ffffff;
            }

            &:disabled {
                cursor: not-allowed;
                background-color: #B8B8B8;
                border: 1px solid #B8B8B8;
                color: #ffffff;
            }
        }
    }

    .modal-container {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000000a1;
        z-index: 999999;
        overflow-y: auto;


        .modal {
            width: 100%;
            max-height: 90%;
            min-height: 100px;
            background: #ffffff;
            bottom: 0;

            position: absolute;
            padding: 20px;
            overflow: auto;
    
            .title {
                font-family: 'Crimson Pro', sans-serif;
                font-weight: 700;
                font-size: 26px;
                margin-bottom: 10px;
                text-align: center;
            }

            .desc {
                text-align: center;
                margin-bottom: 40px;
            }
    
            .close {
                cursor: pointer;
                position: absolute;
                top: 20px;
                right: 20px;

                width: 15px;
                height: 15px;

                @include desktop {
                    width: unset;
                    height: unset;
                }
            }
    
            .items-wrapper {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                max-width: 1120px;
                margin: 0 auto 32px;
                justify-content: center;
    
                .item {
                    display: flex;
                    flex-direction: column;
                    margin: 0 20px 18px 0;
                    text-align: center;
    
                    img {
                        width: 130px;
                        height: 170px;
                        margin-bottom: 16px;
                        box-shadow: 0 3px 6px #00000047;

                        @include desktop {
                            width: 165px;
                            height: 220px;
                        }
                    }
    
                    .remove {
                        cursor: pointer;
                        color: #FF6868;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    &.disabled {
                        opacity: 0.4;
                        pointer-events: none;
                    }
                }
            }
    
            .modal-footer {
                display: flex;
                flex-direction: row-reverse;
    
                button {
                    width: 125px;
                    font-weight: 600;
    
                    &.cancel {
                        background-color: #ffffff;
                        border: 1px solid #FF6868;
                        color: #FF6868;
                        margin-right: 16px;
                    }
    
                    &.next {
                        background-color: #00AEEF;
                        border: 1px solid #00AEEF;
                        color: #ffffff;
                    }
    
                    &:disabled {
                        cursor: not-allowed;
                        background-color: #B8B8B8;
                        border: 1px solid #B8B8B8;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .confirm-container {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000000a1;
        z-index: 999999+1;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .confirm {
            width: 400px;
            z-index: 999;
            padding : 90px 50px 20px;
            box-shadow: 0px 3px 6px #00000047;
            display: flex;
            flex-direction: column;
            text-align: center;
    
            background-image: url('~@/assets/img/home_upperbg.png');
            background-repeat: no-repeat;
            background-color: #ffffff;
            background-size: 100%;
    
            .title {
                color: #00AEEF;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 30px;
            }
    
            .desc {
                width: 260px;
                margin: auto;
                font-size: 14px;

                &.subscribed {
                    margin-bottom: 75px;
                }

                &.sorry {
                    margin-bottom: 20px;
                }

                &.try {
                    margin-bottom: 40px;
                }
            }

            .buttons {
                display: flex;

                .close {
                    color: #FF6868;
                    border: 1px solid #FF6868;
                    background: #ffffff;
                    font-size: 14px;
                    width: 120px;
                    margin: auto;
                }

                .return {
                    color: #ffffff;
                    border: 1px solid #00AEEF;
                    background: #00AEEF;
                    font-size: 14px;
                    width: 120px;
                    margin: auto;
                }
            }
    
        }
    }
}
</style>